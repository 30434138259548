import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Carousel } from 'react-bootstrap'
import PostSummary from './PostSummary'

const PostCarousel = ({posts}) => {
  return (
    <>
      <Carousel controls={true} indicators={false}>

        {posts.map(({node: post}) => (
          <Carousel.Item key={post.title}>
            <PostSummary post={post} />
          </Carousel.Item>
        ))}

      </Carousel>
      <br/>
      <br/>
      <br/>
      <br/>
      <div className="text-center">
        <Link className="small" to={`/blog/`}>full blog</Link>
      </div>
    </>
  )
}


PostCarousel.propTypes = {
  posts: PropTypes.array,
}


export default PostCarousel
