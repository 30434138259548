import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'gatsby'

const PostSummary = ({post}) => {
  return (
    <Row>
      <Col md={4}>
        <Link to={post.fields.slug} className="text-decoration-none text-reset">
          <img src={post.frontmatter.image} className="img-fluid mb-3" alt={post.frontmatter.title}/>
        </Link>
      </Col>
      <Col md={8}>
        <Link to={post.fields.slug} className="text-decoration-none text-reset">
          <h2 className="display-4 font-weight-bold mb-0">
            {post.frontmatter.title}
          </h2>
          <h5 className="mb-3">
            {post.frontmatter.subtitle}
          </h5>
          <p>
            {post.excerpt}
          </p>
        </Link>
      </Col>
    </Row>
  )
}


PostSummary.propTypes = {
  post: PropTypes.object,
}


export default PostSummary
