import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { graphql } from 'gatsby'
import Typing from 'react-typing-animation'
import Layout from '../components/layout'
import Hero from '../components/Hero'
import PostCarousel from '../components/blog/PostCarousel'

const IndexPage = ({data: { allMarkdownRemark: { edges: posts }}}) => {
  return (
    <Layout title="home" topMargin={false}>
      <Hero>
        <h1 className="text-dark">
          <Typing speed={75}>
            Hi, <Typing.Delay ms={750} />
            my name is <Typing.Speed ms={25} />
            <span className="text-underline">Jacob Spizziri</span>
            <Typing.Delay ms={2000} />
            <Typing.Backspace count={14} />
            <span className="text-underline">@jspizziri</span>. <Typing.Speed ms={60} />
            <Typing.Delay ms={2000} />
            Welcome to my website.
            <Typing.Delay ms={1500} />
            <Typing.Speed ms={10} />
            <Typing.Backspace count={22} />
            <Typing.Speed ms={30} />
            I like to build stuff.
            <Typing.Speed ms={10} />
            <Typing.Delay ms={2000} />
            <Typing.Backspace count={22} />
            <Typing.Delay ms={3000} />
            <Typing.Speed ms={30} />
            I'm a business-minded technologist, who loves solving problems.
          </Typing>
        </h1>
      </Hero>
      <Container className="section" style={{ paddingTop: '3em', paddingBottom: '7em' }}>
        <h3 className="text-center font-weight-lighter">
          Some Thoughts
        </h3>
        <br/>
        <br/>

        <PostCarousel posts={posts} />

      </Container>
      <div style={{ backgroundColor: '#e6e8e6' }}>
        <Container className="section text-center">
          <h3 className="text-center font-weight-lighter">
            Here's Where You Can Find Me
          </h3>
          <Row>
            <Col>
              <a href="mailto:jacob.spizziri@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontSize: '5rem' }}
              >
                <i className="fa fa-envelope" aria-hidden="true"></i>
              </a>
            </Col>
            <Col>
              <a href="https://linkedin.com/in/jspizziri"
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontSize: '5rem' }}
              >
                <i className="fa fa-linkedin-square" aria-hidden="true"></i>
              </a>
            </Col>
            <Col>
              <a href="https://github.com/jspizziri"
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontSize: '5rem' }}
              >
                <i className="fa fa-github" aria-hidden="true"></i>
              </a>
            </Col>
            <Col>
              <a href="https://gitlab.com/jspizziri"
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontSize: '5rem' }}
              >
                <i className="fa fa-gitlab" aria-hidden="true"></i>
              </a>
            </Col>
            <Col>
              <a href="https://stackoverflow.com/users/2811583/jspizziri"
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontSize: '5rem' }}
              >
                <i className="fa fa-stack-overflow" aria-hidden="true"></i>
              </a>
            </Col>
            <Col>
              <a href="https://twitter.com/jspizziri"
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontSize: '5rem' }}
              >
                <i className="fa fa-twitter" aria-hidden="true"></i>
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPagePostQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fileAbsolutePath: { regex: "/(blog)/" },
        frontmatter: {
          isPublished: {
           eq: true
          }
        }
      }
      limit: 3
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            subtitle
            image
          }
        }
      }
    }
  }
`
