import React from 'react'
import { Container, Jumbotron } from 'react-bootstrap'
import styles from "./hero.module.scss"

console.log(styles);

const Hero = (props) => {
  const size = props.size && props.size.toLowerCase() === 'small'  ? 'Small' : 'Large'
  return (
    <Jumbotron className={`bg-primary rounded-0 text-serif ${styles[`hero${size}`]} ${styles.hero}`}>
      <Container>
        {props.children}
      </Container>
    </Jumbotron>
  )
}

export default Hero
